<template>
    <header class="page-title">
        <h1><i class="bi bi-speedometer"></i> ダッシュボード</h1>
    </header>

    <div class="row">
        <div class="col-12">
            <section class="section">
                <form class="d-flex gap-3 col-16 offset-4 mb-3" @submit.prevent="fetch()">
                    <button type="button" class="btn btn-secondary" @click="fetchPrev()"><i class="bi bi-caret-left"></i></button>
                    <form-input-month v-model="month" required></form-input-month>
                    <button type="submit" class="btn btn-primary">表示</button>
                    <button type="button" class="btn btn-secondary" @click="fetchNext()"><i class="bi bi-caret-right"></i></button>
                </form>

                <table class="table table-bordered table-layout-fixed text-center">
                    <tbody>
                        <tr v-for="week,w of calendar" :key="w">
                            <td v-for="day,d of week" :key="d" :class="{'table-success': (day === $helper.today()), 'table-secondary': (d > 4)}">
                                <template v-if="day">
                                    <div class="h2 mb-0">{{ $helper.date('j', day) }}</div>
                                    <template v-if="report_loading">
                                        <div class="p-3 text-primary"><text-loader :width="3"></text-loader></div>
                                        <div>
                                            <button class="btn btn-secondary" disabled><i class="bi bi-plus"></i></button>
                                        </div>
                                    </template>
                                    <template v-else-if="day in report_map">
                                        <div><button class="btn btn-link" @click="view(report_map[day])">{{ $helper.clock(report_map[day].work_time_total) }}</button></div>
                                        <div>
                                            <router-link :to="{name: 'ReportEdit', params: {report: report_map[day].report_id}}" class="btn btn-light"><i class="bi bi-pencil-square"></i></router-link>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="p-3 text-secondary">未</div>
                                        <div>
                                            <router-link :to="{name: 'ReportAdd', query: {report_date: day}}" class="btn btn-primary"><i class="bi bi-plus"></i></router-link>
                                        </div>
                                    </template>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </div>
        <div class="col-12 right-col-outer">
            <section class="section right-col-inner">
                <form class="d-flex gap-3 col-16 offset-4 mb-3" @submit.prevent="fetch()">
                    <button type="button" class="btn btn-secondary" @click="fetchPrev()"><i class="bi bi-caret-left"></i></button>
                    <form-input-month v-model="month" required></form-input-month>
                    <button type="submit" class="btn btn-primary">表示</button>
                    <button type="button" class="btn btn-secondary" @click="fetchNext()"><i class="bi bi-caret-right"></i></button>
                </form>
                <div class="right-col-scroll">
                    <div class="bg-light p-3 mb-3">
                        <h4>{{ $store.state.auth.user_name }} さんの稼働</h4>
                        <doughnut-chart :dataset="work_summary_chart_data" :loading="work_loading"></doughnut-chart>
                    </div>
                    <table class="table mb-3">
                        <thead class="table-dark text-center">
                            <tr>
                                <th class="col-20">案件</th>
                                <th class="col-4">稼働</th>
                            </tr>
                        </thead>
                        <tbody v-if="work_loading">
                            <tr v-for="i in Array(3)" :key="i">
                                <td><text-loader :width="12"></text-loader></td>
                                <td class="text-end text-nowrap"><text-loader :width="3"></text-loader></td>
                            </tr>
                        </tbody>
                        <tbody v-else-if="work_summaries.length === 0">
                            <tr>
                                <td colspan="5">該当するユーザがいません</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-for="row of work_summaries" :key="row.project.project_id">
                                <td>{{ row.project.project_name }}</td>
                                <td class="text-end text-nowrap">{{ $helper.clock(row.work_time) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    </div>

    <div class="mb-3">
        <div class="card">
            <h3 class="card-header">リリースノート</h3>
            <div class="card-body">
                <div class="p-3">
                    <h4><span class="text-secondary">2024.06.26</span> 案件分類の追加</h4>
                    <p>これまで案件マスタに2つまで設定可能だった「案件分類」が、<br>
                    4つまで追加可能になりました。<br>
                    これに伴い、案件マスタのインポート定義も変更されています。 </p>
                </div>
                <div class="p-3">
                    <h4><span class="text-secondary">2024.06.26</span> 工数比例案件の売上調整</h4>
                    <p>これまで「工数比例売上」タイプの案件の売上は、<br>
                    作業時間（請求時間）を元に自動計算され、変更できませんでしたが、<br>
                    調整が可能になりました。<br>
                    指定額に固定する方式と、指定額を加算（減産）する方式の、2通りの調整が可能です。</p>
                </div>
                <div class="p-3">
                    <h4><span class="text-secondary">2024.06.26</span> 各種調整</h4>
                    <p>軽微な不具合の修正や、レイアウト変更を行いました。</p>
                </div>
            </div>
            <div class="card-footer text-end">
                <a href="https://github.com/ad5-inc/hitotsuki-guide/blob/master/src/pages/001-release-note.md" target="_blank" rel="noopener noreferrer">リリースノート一覧</a>
            </div>
        </div>
    </div>

    <part-view-report :report="view_report" @close="view_report = null"></part-view-report>
</template>

<script>
import FormInputMonth from '@/components/forms/FormInputMonth';
import TextLoader from '@/components/tools/TextLoader.vue';
import DoughnutChart from '@/components/charts/DoughnutChart';
import PartViewReport from './PartViewReport'
import Report from "@/models/entities/report";
import { calendarOf, addMonth, subMonth } from '@/utilities/month'

export default {
    name: 'Home',
    components: {
        FormInputMonth,
        TextLoader,
        DoughnutChart,
        PartViewReport
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            calendar: [],
            report_loading: true,
            work_loading: true,
            report_map: {},
            work_summaries: [],
            view_report: null,
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.calendar = calendarOf(this.month);

            this.report_loading = true;
            this.report_map = {};
            this.$http.get(`/report/month/${this.month}/me`)
            .then((response) => {
                for (let report of response.data) {
                    this.report_map[report.report_date] = new Report(report);
                }
                this.report_loading = false;
            });

            this.work_loading = true;
            this.$http.get(`/work/summary/me`, {
                params: {
                    month_from: this.month,
                    month_to: this.month,
                }
            })
            .then((response) => {
                this.work_summaries = response.data;
                this.work_loading = false;
            });
        },
        fetchPrev()
        {
            this.month = subMonth(this.month, 1);
            this.fetch();
        },
        fetchNext()
        {
            this.month = addMonth(this.month, 1);
            this.fetch();
        },
        view(report)
        {
            this.view_report = report;
        }
    },
    computed: {
        month: {
            get() {
                return this.$store.state.condition.month;
            },
            set(value) {
                this.$store.commit('condition/setMonth', value);
            },
        },
        work_summary_chart_data() {
            // 売上降順ソートして最大上位10件（＋その他）
            if (this.work_summaries.length === 0) {
                return [];
            }

            let chart_data = [];
            let others = 0;

            for (let row of this.work_summaries) {
                if (chart_data.length < 10) {
                    chart_data.push({
                        label: row.project.project_name,
                        value: row.work_time,
                    });
                } else {
                    others = others + row.work_time;
                }
            }

            if (others > 0) {
                chart_data.push({
                    label: 'その他',
                    value: others,
                });
            }

            return chart_data;
        },
    },
}
</script>

<style scoped>
.right-col-outer {
    position: relative;
}
.right-col-inner {
    position: absolute;
    width: 100%;
    height: calc(100% - 0.5rem);
}
.right-col-scroll {
    height: calc(100% - 3rem);
    padding-right: 0.5rem;
    overflow: scroll;
}
</style>
